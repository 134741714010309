<!-- eslint-disable import/no-cycle -->
<template>
  <Dialog
    :visible.sync="showModal"
    modal
    header="Header"
    class="stop-details-modal"
    :draggable="false"
    :style="{ 'border-radius': '10px', width: '510px' }"
    @hide="closeModal"
  >
    <template #header>
      <div class="inline-flex align-items-center justify-content-center gap-2">
        <span class="font-bold white-space-nowrap stop-details-modal__title"> Hazmat</span>
      </div>
    </template>
    <div class="stop-details-modal__content">
      <div class="modal-template">
        <p>
          The accessorial selected does not cover commodities
          <strong>Hazmat Explosives 1.4, Hazmat Toxic or Posion 6.1. </strong> or
          <strong>Hazmat Radioactive Yellow II</strong>. If your shipment contains any of those
          commodities, please select the specific accessorial by clicking on MORE ACCESSORIALS.
        </p>
      </div>
    </div>
    <div class="stop-details-modal__content-buttons">
      <el-button
        element-loading-background="rgba(231, 231, 231, 0.8)"
        class="stop-details-modal__button-save"
        @click="closeModal()"
      >
        Close
      </el-button>
    </div>
  </Dialog>
</template>

<script>
export default {
  name: 'HazmatModalScalesRates',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      showModal: false,
    };
  },
  watch: {
    visible: {
      handler(newVal) {
        this.showModal = newVal;
      },
    },
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.stop-details-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include modal-template;

  &__content {
    gap: 0;
    line-height: 1.5rem;

    & p {
      text-align: justify;
      font-size: 16px;
    }
  }

  &__title {
    margin: 12px;
  }
}
</style>
