<template>
  <div id="formQuote">
    <div class="row mt-4 ml-0">
      <div class="select-form__container">
        <div class="select-form">
          <el-select class="input-form--select"
            :disabled="isFileUploaded"
            v-model="quote.country_from"
            placeholder="Select one country">
            <el-option v-for="item in countryCodesOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
          </el-select>
          <span class="select-form__span"> Pickup Zip Code </span>
        </div>
        <div class="select-form__container-required">
        <el-autocomplete :disabled="isFileUploaded" class="input-form" v-model="quote.zip_from"
          :fetch-suggestions="querySearchFrom" :clearable="true" placeholder="Please Input"
          @select="handleSelect($event, 'from')">
          <template slot="prepend">Zip From</template></el-autocomplete>
          <span class="scales__content__row__autocomplete--not-found" v-if="requiredFieldZipFrom">
            This field is required.
          </span>
        </div>
      </div>
      <div class="select-form__container">
        <div class="select-form">
          <el-select class="input-form--select"
            v-model="quote.country_to"
            :disabled="isFileUploaded"
            placeholder="Select one country">
            <el-option v-for="item in countryCodesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <span class="select-form__span"> Delivery Zip Code </span>
        </div>
      <div class="select-form__container-required">
        <el-autocomplete :disabled="isFileUploaded" class="input-form" v-model="quote.zip_to"
          :fetch-suggestions="querySearchTo" :clearable="true" placeholder="Please Input"
          @select="handleSelect($event, 'to')">
          <template slot="prepend">Zip To</template></el-autocomplete>
          <span class="scales__content__row__autocomplete--not-found" v-if="requiredFieldZipTo">
            This field is required.
          </span>
        </div>
        </div>
    </div>
    <div class="row mt-4 ml-0">
      <div class="select-form__container">
      <div class="select-form__container-required">
        <el-input :disabled="isFileUploaded" class="input-form" placeholder="100,700,1000"
          @change="handleWeightBrakesChange" v-model="quote.weight_brakes">
          <template slot="prepend">Weight brake</template>
        </el-input>
        <span class="scales__content__row__autocomplete--not-found"
          v-if="requiredFieldWeightBrakes">
          This field is required.
        </span></div>
      </div>
      <div class="select-form__container">
        <div class="select-form__container-required--select">
        <el-select class="input-form__select"
          v-model="quote.units_weight"
          placeholder="Weight Units"
          :disabled="isFileUploaded">
          <el-option v-for="item in unitsOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option><template slot="prefix"><span>Weight Units</span></template>
        </el-select>
        <span class="scales__content__row__autocomplete--not-found" v-if="requiredFieldUnitsWeight">
          This field is required.
        </span>
      </div>
      </div>
    </div>
    <div class="row mt-4 ml-0">
      <div class="select-form__container">
        <div class="select-form__container-required--select">
        <el-select class="input-form__select" :disabled="isFileUploaded" v-model="quote.nmfc"
          placeholder="Select one Nmfc_class">
          <el-option v-for="item in nmfcOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option><template slot="prefix"><span>Nmfc_class</span></template>
        </el-select>
        <span class="scales__content__row__autocomplete--not-found" v-if="requiredFieldNmfc">
          This field is required.
        </span>
        </div>
      </div>
      <div class="select-form__container">
        <div class="select-form__container-required">
        <el-input class="input-form" :disabled="isFileUploaded" placeholder="Please Input a density"
          @change="handleDensityChange" v-model="quote.density">
          <template slot="prepend">Density</template>
        </el-input>
        <span class="scales__content__row__autocomplete--not-found" v-if="requiredFieldDensity">
          This field is required.
        </span>
        <span
          class="scales__content__row__autocomplete--not-found"
          v-if="!isValidDensityRule && quote.nmfc && quote.density"
        >
          Invalid, density must match the class.
        </span>
        </div>
      </div>
    </div>
    <b-overlay :show='busy' rounded opacity='0.6' spinner-small class='d-inline-block'>
      <b-button :disabled='busy' class='scales__content__row--button-next' @click='sendScale()'>
        Send Quote
      </b-button>
    </b-overlay>
  </div>
</template>

<script>

export default {
  name: 'SendQuoteFormByWeight',
  props: {
    scaleData: {
      type: Object,
      default: () => ({}),
    },
    file: {
      type: File,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      quote: {
        zip_from: '',
        zip_to: '',
        weight_brakes: '',
        units_weight: null,
        density: null,
        rates: null,
        country_from: 'US',
        country_to: 'US',
        nmfc: null,
      },
      quote_copy: null,
      aux_zip_to: null,
      requiredFieldZipFrom: false,
      requiredFieldZipTo: false,
      requiredFieldRates: false,
      requiredFieldWeightBrakes: false,
      requiredFieldDensity: false,
      isValidDensityRule: false,
      requiredFieldUnitsWeight: false,
      requiredFieldNmfc: false,
      countryCodesOptions: [
        { label: 'US', value: 'US' },
        { label: 'CA', value: 'CA' },
        { label: 'MX', value: 'MX' },
      ],
      unitsOptions: [
        { label: 'lbs', value: 'lbs' },
        { label: 'kg', value: 'kg' },
        { label: 'ton', value: 'ton' },
      ],
      nmfcOptions: [
        { label: '50', value: '50' },
        { label: '55', value: '55' },
        { label: '60', value: '60' },
        { label: '65', value: '65' },
        { label: '70', value: '70' },
        { label: '85', value: '85' },
        { label: '93', value: '93' },
        { label: '100', value: '100' },
        { label: '125', value: '125' },
        { label: '150', value: '150' },
        { label: '175', value: '175' },
        { label: '250', value: '250' },
        { label: '300', value: '300' },
        { label: '400', value: '400' },
      ],
      locations: [],
      busy: false,
      isValid: false,
      parentValid: false,
      isFileUploaded: false,
    };
  },
  watch: {
    parentValid(newValue) {
      this.parentValid = newValue;
    },
    scaleData: {
      handler(newVal) {
        Object.assign(this.quote, newVal);
      },
      deep: true,
    },
    quote: {
      handler() {
        const validationResults = this.validateFields();
        this.$emit('validation-update', validationResults);
      },
      deep: true,
    },
    file(newVal) {
      if (newVal instanceof FileList) {
        if (this.$parent.verifyFields()) {
          this.readingFile({ target: newVal });
        }
      } else if (newVal instanceof File) {
        if (this.$parent.verifyFields()) {
          this.readingFile({ target: [newVal] });
        }
      }
    },
    'quote.nmfc': {
      handler(value) {
        if (value) {
          this.densityValueRules(value, this.quote.density);
        }
      },
      immediate: true,
    },
    'quote.density': {
      handler(value) {
        if (value) {
          this.densityValueRules(this.quote.nmfc, value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    densityValueRules(nmfcValue, densityValue) {
      if (!nmfcValue || !densityValue) {
        return;
      }
      const rules = [
        { class: '400', lower: 0, upper: 1 },
        { class: '300', lower: 1, upper: 2 },
        { class: '250', lower: 2, upper: 4 },
        { class: '175', lower: 4, upper: 6 },
        { class: '150', lower: 6, upper: 7 },
        { class: '125', lower: 7, upper: 8 },
        { class: '100', lower: 8, upper: 10 },
        { class: '93', lower: 10, upper: 12 },
        { class: '85', lower: 12, upper: 15 },
        { class: '70', lower: 15, upper: 23 },
        { class: '65', lower: 23, upper: 30 },
        { class: '60', lower: 30, upper: 35 },
        { class: '55', lower: 35, upper: 50 },
        { class: '50', lower: 50, upper: 999999999 },
      ];
      const rule = rules.find((item) => item.class === nmfcValue);
      const isValid = densityValue >= rule.lower && densityValue < rule.upper;
      this.isValidDensityRule = isValid;
    },
    async readingFile() {
      this.$router.push({ name: 'EditScalesByWeight', params: { file: this.file } });
      this.isFileUploaded = true;
    },
    async querySearch(queryString, cb, property) {
      // eslint-disable-next-line
      const country = this.quote[property];
      const data = {
        country_code: country,
        q: queryString,
      };
      if (queryString.length >= 2) {
        const response = await this.$store.dispatch('scalesByWeight/getLocations', data);
        if (response && Array.isArray(response)) {
          this.locations = response.map((item) => ({
            value: `${item.city}, ${item.state_code}, ${item.zip_code}`,
            label: item.zip_code,
          }));
        } else {
          this.locations = [];
        }
      }
      cb([...this.locations]);
    },
    async querySearchFrom(queryString, cb) {
      return this.querySearch(queryString, cb, 'country_from');
    },
    async querySearchTo(queryString, cb) {
      return this.querySearch(queryString, cb, 'country_to');
    },
    handleSelect(item, type) {
      let data = {};
      if (item.value && item.value.split(',').length > 2) {
        const value = item.value.split(',')[2];
        if (type === 'from') {
          // eslint-disable-next-line
          data['zip_from'] = value;
          this.quote.from = value;
        } else if (type === 'to') {
          // eslint-disable-next-line
          data['zip_to'] = value;
          this.quote.to = value;
        }
        if (this.quote.from && this.quote.to) {
          data = { ...data, ...{ zip_from: this.quote.from, zip_to: this.quote.to } };
        }
      }
    },
    handleWeightBrakesChange(value) {
      if (Array.isArray(value)) {
        this.quote.weight_brakes = value.join(',');
      } else if (typeof value === 'string') {
        this.quote.weight_brakes = value;
      } else {
        this.quote.weight_brakes = '';
      }
    },
    handleDensityChange(value) {
      if (Array.isArray(value)) {
        this.quote.density = value.join(',');
      } else if (typeof value === 'string') {
        this.quote.density = value;
      } else {
        this.quote.density = '';
      }
    },
    organizeQuoteInArrays() {
      this.quote_copy = {
        zip_from: null,
        zip_to: null,
        weight_brakes: [],
        units_weight: null,
        density: [],
        rates: null,
        nmfc: null,
      };
      const processArray = (value) => {
        if (typeof value === 'string' && value.trim()) {
          return value.split(',').map(Number);
          // eslint-disable-next-line
        } else if (Array.isArray(value)) {
          return value.map(Number);
        }
        return [];
      };
      ['weight_brakes', 'density'].forEach((key) => {
        this.quote_copy[key] = processArray(this.quote[key]);
      });
      this.quote_copy.nmfc = this.quote.nmfc;
      this.quote_copy.units_weight = this.quote.units_weight;
      this.quote_copy = { ...this.quote };
    },
    validateFields() {
      const requiredFields = [
        { name: 'ZipFrom', value: !!this.quote.zip_from },
        { name: 'ZipTo', value: !!this.quote.zip_to },
        { name: 'WeightBrakes', value: !!this.quote.weight_brakes.length },
        { name: 'rates', value: !!this.quote.rates },
        { name: 'Density', value: !!this.quote.density },
        { name: 'UnitsWeight', value: !!this.quote.units_weight },
        { name: 'Nmfc', value: !!this.quote.nmfc },
      ];
      const validationResults = {};
      requiredFields.forEach((field) => {
        validationResults[field.name] = field.value;
        this.isValid = field.value;
        this[`requiredField${field.name.charAt(0).toUpperCase() + field.name.slice(1)}`] = !field.value && !this.isValid;
      });
      return validationResults;
    },
    validateAllFields() {
      this.parentValid = this.$parent.verifyFields();
      return this.parentValid && this.isValid;
    },
    lineItemsDimension(weightBrakes, density, unitsWeight) {
      const dimensionsArray = [];
      weightBrakes.forEach((weight) => {
        let realWeight = 0;
        switch (unitsWeight) {
        case 'kg':
          realWeight = weight * 2.205;
          break;
        case 'ton':
          realWeight = weight * 2000;
          break;
        default:
          realWeight = weight;
        }
        realWeight = realWeight.toFixed(2);
        const cbf = realWeight / density;
        let hu = (cbf * 1728) / 182400;
        hu = Math.ceil(hu);
        let height = (cbf * 1728) / (48 * 40 * hu);
        height = height.toFixed(2);
        const length = 48;
        const width = 40;
        dimensionsArray.push({
          hu,
          length,
          width,
          height,
          realWeight,
        });
      });
      return dimensionsArray;
    },
    async sendScale() {
      this.validateFields();
      this.$emit('send-quote', this.isValid);
      const allValid = this.isValid;
      if (allValid && this.parentValid && this.isValidDensityRule) {
        this.organizeQuoteInArrays();
        const weightBrakesArray = Array.isArray(this.quote_copy.weight_brakes) ? this.quote_copy.weight_brakes : this.quote_copy.weight_brakes.split(',').map(Number);
        const densityArray = Array.isArray(this.quote_copy.density) ? this.quote_copy.density : this.quote_copy.density.split(',').map(Number);
        const dimensions = await this.lineItemsDimension(
          weightBrakesArray,
          densityArray,
          this.quote_copy.units_weight,
        );
        const hauls = this.haulsConstructor(dimensions);
        this.$store.commit('scale/setHauls', hauls);
        this.isLoading = true;
        const response = await this.$store.dispatch('scale/sendQuote');
        if (response.status === 202) {
          this.requiredFieldZipFrom = false;
          this.requiredFieldZipTo = false;
          this.requiredFieldWeightBrakes = false;
          this.requiredFieldDensity = false;
          this.requiredFieldUnitsWeight = false;
          this.clearForm();
          this.$parent.resetParentComponents();
          this.$store.commit('scale/setHauls', []);
          this.$swal({
            icon: 'success',
            title: 'The scale has been sent',
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            this.$router.replace({
              name: 'ScalesByWeight',
              params: { resetInfo: true },
              query: { refresh: new Date().getTime() },
            });
          });
        }
        this.isLoading = false;
      }
    },
    haulsConstructor(dimensions) {
      const hauls = [];
      dimensions.forEach((dimension) => {
        const haul = {};
        haul.line_items = [];
        const lineItems = {
          weight: parseFloat(dimension.realWeight),
          length: parseFloat(dimension.length),
          width: parseFloat(dimension.width),
          height: parseFloat(dimension.height),
          hu_count: dimension.hu,
        };
        haul.order = hauls.length + 1;
        if ((!this.quote_copy.zip_from && !this.quote_copy.zip_to)
          || (!this.quote_copy.country_from && !this.quote_copy.country_to)
        ) {
          hauls[hauls.length - 1].line_items.push(lineItems);
        } else {
          haul.zip_from = this.quote_copy.from.toString().toUpperCase().trim();
          haul.zip_to = this.quote_copy.to.toString().toUpperCase().trim();
          haul.country_from = this.quote_copy.country_from.toString().toUpperCase();
          haul.country_to = this.quote_copy.country_to.toString().toUpperCase();
          haul.nmfc_class = this.quote_copy.nmfc.toString();
          haul.line_items.push(lineItems);
          hauls.push(haul);
        }
      });
      return hauls;
    },
    clearForm() {
      this.quote = {
        zip_from: null,
        zip_to: null,
        weight_brakes: [],
        units_weight: null,
        density: [],
        rates: null,
        country_from: 'US',
        country_to: 'US',
      };
      this.isFileUploaded = false;
    },
  },
};
</script>

<style lang='scss' scoped>
#formQuote {
  margin: 20px;
}

.scales {
  display: flex;

  &__content {
    width: 91%;
    margin-top: 2rem;

    &__row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin: 2rem 1.5rem;

      &--button-next {
        @include primary-button;
        margin: 2rem 0 8rem;
      }

      &__autocomplete {
        display: flex;
        flex-direction: column;
        width: 80%;
        align-items: flex-start;

        &--not-found {
          font-size: 11px;
          color: $color-user-busy;
          line-height: 20px;
        }
      }
    }
  }
}

#alert {
  margin-top: 30px;
}
.select-form {
  display: flex;
  flex-direction: column;

  &__span {
    text-align: center;
    font-size: 12px;
    font-style: italic;
  }

  &__container {
    width: 50%;
    display: flex;
    gap: 1rem;
  }
  &__container-required {
    display: flex;
    flex-direction: column;
    width: 100%;
    &--select {
    display: flex;
    flex-direction: column;
    width: 340%;
    }
  }
}

.row {
  justify-content: space-between;
  gap: 3rem;
  flex-wrap: nowrap;
}

:deep {
  .el-input-group__prepend {
    background-color: #14a2b8;
    color: #ffffff;
  }

  .el-autocomplete {
    width: 100%;
  }

  .el-input__inner {
    text-align: center;
  }

  .el-input__prefix {
    display: flex;
    width: 100px;
    height: 40px;
    color: white;
    left: 0;
    background-color: #14a2b8;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    transition: all .3s;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1020px) {
  .input-form {
    width: 100%;
    height: 40px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .input-form {
    width: 100%;
    height: 40px;
  }
}
</style>
