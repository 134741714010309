<!-- eslint-disable import/no-cycle -->
<template>
  <div>
    <div>
      <el-badge :value="locationAccessorials.length" class="accessorial-badge">
        <el-button label="Show" class="accessorial-modal-button" @click="visible = true">
          {{ accessorialsButtonName }}
        </el-button>
      </el-badge>
    </div>
    <Dialog
      :visible.sync="visible"
      modal
      header="Header"
      class="modal-template"
      :draggable="false"
      :style="{ 'border-radius': '10px' }"
      :breakpoints="{ '1199px': '50vw', '1080px': '70vw', '700px': '90vw' }"
    >
      <template #header>
        <div class="inline-flex align-items-center justify-content-center gap-2">
          <span class="font-bold white-space-nowrap modal-template__title">
            {{ accessorialsTitleName }}
            <el-tooltip placement="right-start" effect="light">
              <template #content>
                <div class="tooltip__width">
                  {{ messageAccessorials }}
                </div>
              </template>
              <i class="pi pi-info-circle" />
            </el-tooltip>
          </span>
        </div>
      </template>
      <div class="modal-template__content">
        <article class="search-container">
          <Search @searchText="searchText" @clearSearch="resetAccessorialsType" />
        </article>
        <article :key="renderComponent" class="accessorial-checkbox-container">
          <section class="accessorial-checkbox-container__section">
            <h6>General</h6>
            <div
              v-if="generalAccessorials.length > 0"
              class="accessorial-checkbox-container__checkbox-section"
            >
              <div
                v-for="(accessorial, index) in generalAccessorials"
                :key="index"
                class="accessorial-checkbox-container__checkboxes-list"
              >
                <Checkbox
                  v-model="accessorialSelected.general[accessorial.id]"
                  :binary="true"
                  :inputId="`checkbox-general-${accessorial.id}`"
                  :value="accessorialSelected.general[accessorial.id]"
                  @change="updateAccessorial(accessorial, 'general')"
                />
                <label
                  element-loading-background="rgba(231, 231, 231, 0.8)"
                  class="accessorial-checkbox-container__checkboxes-label"
                  :for="`checkbox-general-${accessorial.id}`"
                >
                  {{ accessorial.accessorial_name }}
                </label>
              </div>
            </div>
            <span v-else class="accessorial-checkbox-container__checkbox-section"
              >No accessorials found</span
            >
          </section>
          <br />
          <section class="accessorial-checkbox-container__section">
            <h6>Pick up</h6>
            <div
              v-if="pickupAccessorials.length > 0"
              class="accessorial-checkbox-container__checkbox-section"
            >
              <div
                v-for="(accessorial, index) in pickupAccessorials"
                :key="index"
                class="accessorial-checkbox-container__checkboxes-list"
              >
                <Checkbox
                  v-model="accessorialSelected.pickup[accessorial.id]"
                  :binary="true"
                  :inputId="`checkbox-pickup-${accessorial.id}`"
                  :value="accessorialSelected.general[accessorial.id]"
                  @change="updateAccessorial(accessorial, 'pickup')"
                />
                <label
                  element-loading-background="rgba(231, 231, 231, 0.8)"
                  class="accessorial-checkbox-container__checkboxes-label"
                  :for="`checkbox-pickup-${accessorial.id}`"
                >
                  {{ accessorial.accessorial_name }}
                </label>
              </div>
            </div>
            <span v-else class="accessorial-checkbox-container__checkbox-section"
              >No accessorials found</span
            >
          </section>
          <br />
          <section
            class="accessorial-checkbox-container__section \
                accessorial-checkbox-container__section--without-separetor"
          >
            <h6>Delivery</h6>
            <div
              v-if="deliveryAccessorials.length > 0"
              class="accessorial-checkbox-container__checkbox-section"
            >
              <div
                v-for="(accessorial, index) in deliveryAccessorials"
                :key="index"
                class="accessorial-checkbox-container__checkboxes-list"
              >
                <Checkbox
                  v-model="accessorialSelected.delivery[accessorial.id]"
                  :binary="true"
                  :inputId="`checkbox-delivery-${accessorial.id}`"
                  :value="accessorialSelected.delivery[accessorial.id]"
                  @change="updateAccessorial(accessorial, 'delivery')"
                />
                <label
                  element-loading-background="rgba(231, 231, 231, 0.8)"
                  class="accessorial-checkbox-container__checkboxes-label"
                  :for="`checkbox-delivery-${accessorial.id}`"
                >
                  {{ accessorial.accessorial_name }}
                </label>
              </div>
            </div>
            <span v-else class="accessorial-checkbox-container__checkbox-section"
              >No accessorials found</span
            >
          </section>
        </article>
        <br />
        <div class="modal-template__content-buttons">
          <el-button class="modal-template__button-save" @click="visible = false"> Save </el-button>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Search from './SearchBarScalesByWeight.vue';

export default {
  name: 'MoreAccessorialsScalesRates',
  props: {
    combinedAccessorials: {
      type: Array,
      default: null,
    },
  },
  components: {
    Search,
  },
  data() {
    return {
      visible: false,
      accessorialsTitleName: 'More Accessorials',
      accessorialsButtonName: 'More Accessorials',
      messageAccessorials:
        'Enter the total weight per line, which should be the sum of the weights of all units on each line. Doublecheck the Total Weight field before proceeding.',
      renderComponent: 0,
      generalAccessorials: [],
      pickupAccessorials: [],
      deliveryAccessorials: [],
      allAccessorials: [],
      accessorialSelected: { general: {}, pickup: {}, delivery: {} },
      locationAccessorials: [],
    };
  },
  created() {
    this.sortAccessorialsType(this.combinedAccessorials);
    this.accessorialSelected = { general: {}, pickup: {}, delivery: {} };
    this.checkAccessorialsType();
  },
  methods: {
    checkAccessorials(accessorialsArray, accessorialType) {
      accessorialsArray.forEach((accessorial) => {
        if (this.locationAccessorials.includes(accessorial?.id)) {
          this.accessorialSelected[accessorialType][accessorial?.id] = true;
        }
      });
    },
    checkAccessorialsType() {
      this.checkAccessorials(this.generalAccessorials, 'general');
      this.checkAccessorials(this.pickupAccessorials, 'pickup');
      this.checkAccessorials(this.deliveryAccessorials, 'delivery');
    },
    filterMostCommonAccessorials(accessorialsArray) {
      return accessorialsArray.filter((accessorial) => !accessorial.most_common);
    },
    sortAccessorialsType(accessorialsArray) {
      this.generalAccessorials = this.filterMostCommonAccessorials(
        accessorialsArray?.filter(
          (accessorial) => accessorial?.accessorial_type === 'general',
        ),
      );
      this.pickupAccessorials = this.filterMostCommonAccessorials(
        accessorialsArray?.filter(
          (accessorial) => accessorial?.accessorial_type === 'pick up',
        ),
      );
      this.deliveryAccessorials = this.filterMostCommonAccessorials(
        accessorialsArray?.filter(
          (accessorial) => accessorial?.accessorial_type === 'delivery',
        ),
      );
    },
    async updateAccessorial(accessorial, accessorialType) {
      let accessorialUpdated = [...this.locationAccessorials];
      if (this.accessorialSelected[accessorialType][accessorial.id]) {
        accessorialUpdated.push(accessorial.id);
      } else {
        accessorialUpdated = accessorialUpdated.filter((acc) => accessorial.id !== acc);
      }
      await this.updateAccessorialsLocation(accessorialUpdated);
      this.$emit('accessorialListUpdated', accessorialUpdated);
    },
    async updateAccessorialsLocation(accessorialUpdated) {
      this.locationAccessorials = accessorialUpdated;
    },
    async searchText(value) {
      if (value) {
        this.sortAccessorialsType(this.combinedAccessorials);
        this.generalAccessorials = this.filterAccessorialsearched(this.generalAccessorials, value);
        this.pickupAccessorials = this.filterAccessorialsearched(this.pickupAccessorials, value);
        this.deliveryAccessorials = this.filterAccessorialsearched(
          this.deliveryAccessorials,
          value,
        );
      } else {
        this.resetAccessorialsType();
      }
    },
    filterAccessorialsearched(accessorialsArray, text) {
      // eslint-disable-next-line
      return accessorialsArray?.filter((accessorial) => accessorial?.accessorial_name?.toLowerCase()?.includes(text));
    },
    resetAccessorialsType() {
      this.sortAccessorialsType(this.combinedAccessorials);
    },
  },
};
</script>

<style lang="scss" scoped>
// Modal styles
.accessorial-modal-button {
  color: #ffffff;
  background-color: #17a2b8;
  border: none;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  border: none;
  height: 100%;
  margin: auto;
  width: 100%;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #00a2b3;
  }
}

.modal-template {
  @include modal-template;
  &__title {
    font-size: 24px;
    font-weight: bold;
    & i {
      font-family: "primeicons";
      font-weight: bold;
    }
  }
  &__content {
    border-top: none;
    & p {
      font-size: 19px;
    }
  }
  &__button-save {
    font-weight: 400;
    font-size: 14.5px;
  }
}

.accessorial-badge {
  width: 100%;
}

.accessorial-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: #00b9cc;
}

// Search styles
.search-container {
  margin: 0 0.5rem 0;
  width: 100%;
}

// Checkbox styles
.accessorial-checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  width: 100%;

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 33%;
    border-right: 1.5px solid #00b9cc;

    &--without-separetor {
      border-right: none;

      h6 {
        margin: 0.5rem;
        padding-bottom: 0.5rem;
        margin-left: 0.5rem;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #043663;
      }
    }

    h6 {
      margin: 0.5rem;
      padding-bottom: 0.5rem;
      margin-left: 0.5rem;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #043663;
    }
  }

  &__checkboxes-list {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 7px;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }

  &__checkbox-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 188px;
    border-top: 1px solid #00b9cc;
    padding: 1rem 0.5rem 0 0.5rem;
    overflow: scroll;
    overflow-x: hidden;
  }

  span {
    padding-top: 1rem;
    align-items: center;
    color: #043663;
  }

  &__checkboxes-label {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: normal;

    @media (max-width: 800px) {
      font-size: 14px;
    }

    color: #043663;
  }
}

@media (max-width: 550px) {
  .accessorial-modal-button {
    width: 100%;
  }

  .modal-template {
    height: 80vh;
  }

  .accessorial-checkbox-container {
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    overflow-y: scroll;
    height: 50vh;

    &__section {
      flex-wrap: nowrap;
      width: 100%;
      border-right: none;
    }

    &__checkbox-section {
      border-top: none;
      height: 100%;
    }
  }

  ::-webkit-scrollbar {
    width: 4px !important;
  }
}

.tooltip {
  &__width {
    width: 250px;
  }

  &__text {
    font-size: 14px;
    line-height: 25px;
    display: flex;
    flex-direction: column;
    margin: 12px;
  }

  &__list {
    font-size: 14px;
    line-height: 20px;
    margin: 12px;
    color: #043663;
  }

  &__list ul {
    padding-left: 10px;
  }
}

.more-accesorials-text {
  text-align: center;
  cursor: pointer;
}

// Search styles
:deep(.searchbar) {
  background-color: white;
  width: 100% !important;
  border: 2px solid #00b9cc;
}

// Badge Styles
:deep(.el-badge__content) {
  background-color: white;
  color: #00b9cc;
  border: 1px solid #00b9cc;
  font-weight: 700;
  width: 20px;
  height: 20px;
}

:deep(.p-dialog .p-dialog-content) {
  background: #efefef;
  border-radius: 0px 0px 10px 10px;
  padding: 1rem 1.5rem 1rem 1.5rem;
}

:deep(.p-dialog .p-dialog-header) {
  background: #efefef;
  border-radius: 10px 10px 0px 0px;
  padding: 4% 4% 0% 4%;
}

// Checkbox styles
:deep(.p-checkbox .p-checkbox-box) {
  border: 2px solid #00b9cc;
}

:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus) {
  box-shadow: none;
}

// Scrollbar
::-webkit-scrollbar {
  width: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00b9cc;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00b9cc;
}
</style>
