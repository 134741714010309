<template>
  <div class="searchbar">
    <div class="searchbar-content">
      <i class="pi pi-search searchbar-content__icon" @click="search" />
      <div class="searchbar-content__input-container">
        <input
          v-model="searchText"
          type="text"
          :placeholder="searchPlaceholder"
          class="searchbar-content__input"
          @keyup.enter="search()"
        />
        <button
          v-show="searchText"
          class="searchbar-content__delete-word"
          @click="clearSearchField"
        >
          <i class="pi pi-times" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'SearchBarScalesRates',
  data() {
    return {
      searchText: '',
      searchPlaceholder: 'Search...',
    };
  },
  computed: {},
  created() {},
  watch: {
    searchText: {
      handler(newVal) {
        if (newVal !== '') {
          this.$emit('searchText', this.searchText.toLowerCase());
        } else {
          this.clearSearchField();
        }
      },
      deep: true,
    },
  },
  methods: {
    search() {
      if (this.searchText !== '') {
        this.$emit('searchText', this.searchText.toLowerCase());
      } else {
        this.clearSearchField();
      }
    },
    clearSearchField() {
      this.searchText = '';
      this.$emit('clearSearch');
    },
  },
};
</script>

<style scoped lang="scss">
.searchbar {
  width: 37%;
  height: 36px;
  padding: 5px 10px;
  border-radius: 15px;
  border: 2px solid #c0c4cc;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: start;

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 0px;
  }

  &:hover .searchbar-content__delete-word {
    visibility: visible;
  }
}

.searchbar-content {
  position: relative;
  display: flex;
  text-align: start;
  align-items: center;
  width: 100%;

  &__icon {
    height: auto;
    cursor: pointer;
    color: #00b9cc;
  }

  &__input-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__input {
    width: 100%;
    margin-left: 5px;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #043663;
  }

  &__delete-word {
    padding: 0;
    outline: none;
    border: none;
    background: none;
    color: #5a607f;
    visibility: hidden;
    cursor: pointer;
  }
}
</style>
