<template>
  <div class='scales'>
    <div class='scales__sidebar'>
      <Sidebar />
    </div>
    <div class='scales__content'>
      <h1 class='scales__content__tittle'>Bids By Weight</h1>
      <hr class='scales__content__separator' />
      <div
        class='d-flex justify-content-center align-items-center spinner-container'
        v-if='isLoading'
      >
        <img src='@/assets/gif/spinner.gif' width='200' height='200' />
      </div>
      <div v-else>
        <div class='scales__content__row'>
          <div class='d-flex justify-content-between'  style="width:100%; gap: 10px;">
            <b-form-checkbox id="checkbox-requote" v-model="requote"
              name="checkbox-requote" @change="changeRequote()">
              Requote
            </b-form-checkbox>
            <div class='d-flex justify-content-end'  style="width:100%; gap: 10px;">
          <b-overlay :show='busy' rounded opacity='0.6' spinner-small class='d-flex'>
            <b-form-file
          accept=".xlsx, .xls"
          placeholder="Upload Excel File"
          v-model="file"
          :disabled="!verifyFieldsSend || !sonValid"
        >
        </b-form-file>
        <button @click="clearFile" v-if="file" class="clear-button">
          <b-icon icon="x-circle" scale="1"></b-icon></button>
        </b-overlay>
        <b-overlay :show='busy' rounded opacity='0.6' spinner-small class='d-inline-block'>
            <b-button :disabled='busy' @click='downloadFormat' class='scales__content__row--button'
              ><b-icon icon='cloud-download-fill' aria-hidden='true'></b-icon> Download
              Format</b-button
            >
          </b-overlay>
        </div>
        </div>
        </div>
        <div class='scales__content__row'>
          <div class='scales__content__row__column'>
            <label for='subject'> <span class='scales__content__required'>*</span>Subject: </label>
            <div class='scales__content__row__autocomplete'>
              <b-form-input
                v-model='subject'
                placeholder='Enter the subject'
                class='scales__content__row__input'
                size='sm'
                style='width: 100%'
                @input='changeSubject()'
              >
              </b-form-input>
              <span
                class='scales__content__row__autocomplete--not-found'
                v-if='requiredFieldSubject'
              >
                This field is required.
              </span>
            </div>
          </div>
        </div>
        <div class='scales__content__row'>
          <div class='scales__content__row__column'>
            <label for='notify'> <span class='scales__content__required'>*</span>Notify To: </label>
            <div class='scales__content__row__autocomplete'>
              <b-form-tags
                input-id='tags-state-event'
                v-model='notify'
                :tag-validator='validator'
                placeholder='Enter valid emails'
                separator=' '
                style='width: 100%'
                size='sm'
                class='scales__content__row__input'
                invalidTagText='Invalid email(s)'
                @input='changeNotifyTo()'
              ></b-form-tags>
              <span
                class='scales__content__row__autocomplete--not-found'
                v-if='requiredFieldNotify'
              >
                This field is required.
              </span>
            </div>
          </div>
          <div class='scales__content__row__column'>
            <label for='carriers'>Carriers Excluded:</label>
            <MultiSelect
              v-model='carriersSelected'
              :options='allCarriers'
              dataKey='id'
              optionLabel='carrier_name'
              placeholder='Select carriers'
              class='scales__content__row__input'
              v-tooltip.bottom="'Please select the carriers that are not going to be included'"
            >
              <template #option='slotProps'>
                <div>
                  <span>
                    {{ slotProps.option.carrier_scac }} - {{ slotProps.option.carrier_name }}
                  </span>
                </div>
              </template>
            </MultiSelect>
          </div>
        </div>
        <div class='scales__content__row'>
          <div class='scales__content__row__column'>
            <label for='customer'>
              <span class='scales__content__required'>*</span>Customer:
            </label>
            <div class='scales__content__row__autocomplete'>
              <AutoComplete
                forceSelection
                v-model='customer'
                :suggestions='filteredCustomers'
                @complete='searchCustomer($event)'
                field='name'
                class='scales__content__row__input'
                @item-select='changeCustomer()'
                placeholder='Search by name'
                style='width: 100%'
              />
              <span class='scales__content__row__autocomplete--not-found' v-if='customersNotFound'>
                Customer not found.
              </span>
              <span
                class='scales__content__row__autocomplete--not-found'
                v-if='requiredFieldCustomer'
              >
                This field is required.
              </span>
            </div>
          </div>
          <div class='scales__content__row__column'>
            <label for='mode'> <span class='scales__content__required'>*</span>Mode: </label>
            <div class='scales__content__row__autocomplete'>
              <Dropdown
                v-model='modeSelected'
                :options='allModes'
                optionLabel='name'
                style='width: 100%'
                placeholder='Select a mode'
                class='scales__content__row__input'
                :filter='true'
                @change='changeMode()'
              />
            </div>
          </div>
        </div>
        <div class='scales__content__row'>
          <div class="scales__content__row__column">
            <label for="priority">
              <span class="scales__content__required">*</span>Priority:
            </label>
            <b-form-select v-model="priority" :options="priorityOptions"
              size="sm" class="scales__content__row__input"
              @change="changePriority()">
            </b-form-select>
          </div>
          <div class="scales__content__row__create-on">
            <b-form-select v-model="createOn" :options="createOptions"
              size="sm" class="scales__content__row__input"
              @change="changeCreateOn()">
            </b-form-select>
          </div>
        </div>
        <hr>
        <div v-if="!isLoading" v-loading="isLoading" class="accessorials">
          <div class="alert">
            <b-alert class="alert__content" show variant="warning">
            Please select the mode before accessorials.</b-alert>
          </div>
          <div class="pickup-and-delivery-accessorials">
            <div v-for="accessorialType in ['pickup', 'delivery']" :key="accessorialType">
              <div v-for="(accessorial, index) in mostCommonAccessorialsByType[accessorialType]"
                :key="index" class="pickup-and-delivery-accessorials__content">
                <Checkbox v-model="mostCommonAccessorialsSelected"
                  :input-id="`checkbox-general-${accessorial.accessorial_id}`"
                    :value="accessorial" />
                <label class="pickup-and-delivery-accessorials__label"
                  :for="`checkbox-general-${accessorial.accessorial_id}`">
                    {{accessorial.accessorial_name }}</label>
              </div>
            </div>
          </div>
          <hr>
          <div class="more-accessorials">
            <div class="general-accessorials">
              <div v-for="(accessorial, index) in mostCommonAccessorialsByType.general" :key="index"
                class="general-accessorials__content">
                <Checkbox v-model="mostCommonAccessorialsSelected"
                  :input-id="`checkbox-general-${accessorial.accessorial_id}`" :value="accessorial"
                  @change="checkHazmat(accessorial)" />
                <label class="general-accessorials__label"
                  :for="`checkbox-general-${accessorial.accessorial_id}`">
                  {{ accessorial.accessorial_name }}</label>
              </div>
            </div>
            <div class="more-accessorials__button">
              <MoreAccessorials :combinedAccessorials="combinedAccessorials"
                @accessorialListUpdated="accessorialListUpdated" />
            </div>
          </div>
          <HazmatModal :visible="visible" @close="closeModal" />
        </div>
        <div class='scales__content__row'>
          <SendQuoteForm class='scales__content__row__line' ref="sendQuoteForm"
            :scaleData="scaleData" @send-quote="handleSendQuote" :file="file"
            @validation-update="handleValidationUpdate"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import MoreAccessorials from './components/AccessorialsModalScalesByWeight/MoreAccessorialsScalesByWeight.vue';
import HazmatModal from './components/AccessorialsModalScalesByWeight/HazmatModalScalesByWeight.vue';
import SendQuoteForm from './components/SendQuotesFormScalesByWeight.vue';

export default {
  components: {
    Sidebar,
    MoreAccessorials,
    HazmatModal,
    SendQuoteForm,
  },
  name: 'ScalesByWeight',
  data() {
    return {
      subject: '',
      notify: [],
      carriersSelected: [],
      customer: '',
      filteredCustomers: null,
      modeSelected: {
        id: 'a0k1I0000005NnyQAE',
        name: 'LTL',
      },
      createOn: false,
      createOptions: [
        { value: true, text: 'CREATE LOAD ON REVENOVA' },
        { value: false, text: 'DO NOT CREATE LOAD ON REVENOVA' },
      ],
      priority: 2,
      priorityOptions: [
        { value: 1, text: 'Low' },
        { value: 2, text: 'Medium' },
        { value: 3, text: 'High' },
      ],
      busy: false,
      allModes: [
        { id: 'a0k1I0000005NnyQAE', name: 'All Options' },
        { id: 'a0k1I0000005NnyQAE', name: 'LTL' },
        { id: 'a0k1I0000005NnyQAE', name: 'LTL Including Flatbed' },
        { id: 'a0kRu000001ZkPBIA0', name: 'FTL' },
        { id: 'a0k1I00000B7RocQAF', name: 'Flatbed' },
        { id: 'a0k1I000000WkMEQA0', name: 'Reefer' },
        { id: 'a0k1I000000WkMOQA0', name: 'Expedited' },
      ],
      allCarriers: [],
      customersNotFound: false,
      requiredFieldNotify: false,
      requiredFieldCustomer: false,
      requiredFieldSubject: false,
      isLoading: false,
      validationError: null,
      scaleData: null,
      file: null,
      verifyFieldsSend: true,
      sonValid: true,
      allAccessorials: [],
      mostCommonAccessorialsByType: {
        pickup: [],
        delivery: [],
        general: [],
      },
      mostCommonAccessorials: [],
      accessorialsTypes: ['pickup', 'delivery', 'general'],
      mostCommonAccessorialsSelected: [],
      accessorialsListById: [],
      visible: false,
      parentValid: false,
      requote: false,
      multiservice: false,
      partial_flatbed_included: false,
    };
  },
  computed: {
    combinedAccessorials: {
      get() {
        return this.allAccessorials;
      },
    },
  },
  watch: {
    subject(newValue) {
      this.verifyFieldsSend = !!newValue && this.notify.length > 0 && !!this.customer;
    },
    notify(newValue) {
      this.verifyFieldsSend = !!this.subject && newValue.length > 0 && !!this.customer;
    },
    customer(newValue) {
      this.verifyFieldsSend = !!this.subject && this.notify.length > 0 && !!newValue;
    },
  },
  async created() {
    this.isLoading = true;
    if (this.$route.params.resetInfo) {
      this.resetInfo();
    } else {
      this.getAllInfo();
    }
    if (this.modeSelected) {
      await this.getAccessorialsByMode();
    }
    const carriers = await this.$store.dispatch('scale/getAllCarriers');
    this.allCarriers = carriers.data;
    this.isLoading = false;
  },
  methods: {
    resetInfo() {
      const scale = {
        requote: false,
        subject: '',
        create_load: false,
        notify: [],
        customer: '',
        mode: {
          id: 'a0k1I0000005NnyQAE',
          name: 'LTL',
        },
        accessorials: [],
        carriers_exclude: [],
        priority: 2,
      };
      this.$store.commit('scale/resetScale', scale);
    },
    getAllInfo() {
      this.requote = this.$store.getters['scale/showRequote'];
      this.subject = this.$store.getters['scale/showSubject'];
      this.notify = this.$store.getters['scale/showNotifyTo'];
      this.carriersSelected = this.$store.getters['scale/showCarriersExclude'];
      this.customer = this.$store.getters['scale/showCustomer'];
      this.modeSelected = this.$store.getters['scale/showMode'];
      this.priority = this.$store.getters['scale/showPriority'];
      this.createOn = this.$store.getters['scale/showCreateLoad'];
      this.multiservice = this.$store.getters['scale/showMultiservice'];
      this.partial_flatbed_included = this.$store.getters['scale/showPartialFlatbedIncluded'];
    },
    async searchCustomer(event) {
      const params = {
        name: event.query,
      };
      const response = await this.$store.dispatch('scale/getCustomersByName', params);
      if (response.status === 404) {
        this.filteredCustomers = null;
        this.customersNotFound = true;
      } else {
        this.filteredCustomers = response.data;
        this.customersNotFound = false;
      }
    },
    validator(tag) {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(tag);
    },
    changeRequote() {
      this.$store.commit('scale/setRequote', this.requote);
    },
    changeSubject() {
      this.$store.commit('scale/setSubject', this.subject);
    },
    changeNotifyTo() {
      this.$store.commit('scale/setNotifyTo', this.notify);
    },
    changeCarriers() {
      this.$store.commit('scale/setCarriersExclude', this.carriersSelected);
    },
    async getAccessorialsByMode() {
      this.isLoading = true;
      const params = { mode_name: this.modeSelected.name };
      try {
        const accessorials = await this.$store.dispatch('scale/getAllAccessorials', params);
        this.allAccessorials = accessorials.data;
        this.filterMostCommonAccessorials();
      } finally {
        this.isLoading = false;
      }
    },
    changeMode() {
      const { id, name } = this.modeSelected;
      let adjustedName;
      if (['All Options', 'LTL', 'LTL Including Flatbed'].includes(name)) {
        adjustedName = 'LTL';
      } else {
        adjustedName = name;
      }

      this.$store.commit('scale/setMode', { id, name: adjustedName });

      this.multiservice = name === 'All Options';
      this.partial_flatbed_included = name === 'LTL Including Flatbed';

      this.$store.commit('scale/setMultiservice', this.multiservice);
      this.$store.commit('scale/setPartialFlatbedIncluded', this.partial_flatbed_included);

      this.getAccessorialsByMode();
    },
    changeCustomer() {
      this.$store.commit('scale/setCustomer', this.customer);
    },
    changeCreateOn() {
      this.$store.commit('scale/setCreateLoad', this.createOn);
    },
    changePriority() {
      this.$store.commit('scale/setPriority', this.priority);
    },
    handleSendQuote() {
      this.parentValid = this.verifyFields();
      if (this.parentValid) {
        this.$refs.sendQuoteForm.validateAllFields();
      }
    },
    handleValidationUpdate(validationResults) {
      const hasErrors = Object.values(validationResults).some((value) => value);
      this.sonValid = !hasErrors;
    },
    verifyFields() {
      const fields = [
        { name: 'notify', value: this.notify.length > 0 },
        { name: 'customer', value: !!this.customer },
        { name: 'subject', value: !!this.subject },
      ];
      this.saveAccessorials();
      let hasErrors = false;
      fields.forEach((field) => {
        const fieldName = field.name;
        const isValid = field.value;
        this[`requiredField${fieldName.charAt(0).toUpperCase()}${fieldName.slice(1)}`] = !isValid;
        if (!isValid) {
          hasErrors = true;
        }
      });
      if (hasErrors || this.validationError) {
        this.$message({
          showClose: true,
          type: 'error',
          message: 'Please fill in all the required fields (*)',
          showConfirmButton: false,
          duration: 2000,
        });
      }
      const scale = {
        subject: this.subject,
        notify_to: this.notify,
        customer: this.customer,
        carriers_exclude: this.carriersSelected,
        mode: this.modeSelected,
      };
      this.scaleData = scale;
      return !hasErrors && !this.validationError;
    },
    saveAccessorials() {
      this.changeCarriers();
      const pickupSelected = [];
      const deliverySelected = [];
      const generalSelected = [];
      const moreAccessorials = this.accessorialsListById;
      this.mostCommonAccessorialsSelected.forEach((accessorial) => {
        switch (accessorial.accessorial_type) {
        case 'pick up':
          pickupSelected.push(accessorial.id);
          break;
        case 'delivery':
          deliverySelected.push(accessorial.id);
          break;
        case 'general':
          generalSelected.push(accessorial.id);
          break;
        default:
        }
      });
      const allAccessorialsSelected = [
        ...pickupSelected,
        ...deliverySelected,
        ...generalSelected,
        ...moreAccessorials,
      ];
      const uniqueAccessorials = [...new Set(allAccessorialsSelected)];
      this.$store.commit('scale/setAccessorials', uniqueAccessorials);
    },
    accessorialListUpdated(accessorialList) {
      this.accessorialsListById = [...accessorialList];
    },
    filterAccessorialsByType() {
      if (!this.mostCommonAccessorialsByType || !this.mostCommonAccessorials) {
        return;
      }
      const accessorialMap = new Map();
      this.accessorialsTypes.forEach((type) => {
        const accessorialType = type === 'pickup' ? 'pick up' : type;
        if (!accessorialMap.has(type)) {
          accessorialMap.set(type, this.mostCommonAccessorials.value?.filter((acc) =>
            // eslint-disable-next-line
            acc.accessorial_type === accessorialType,
            // eslint-disable-next-line
          ));
        }
        this.mostCommonAccessorialsByType[type] = accessorialMap.get(type);
      });
    },
    filterMostCommonAccessorials() {
      this.isLoading = true;
      this.mostCommonAccessorials.value = [];
      this.mostCommonAccessorialsByType.value = {
        pickup: [],
        delivery: [],
        general: [],
      };
      this.mostCommonAccessorials.value = this.allAccessorials.filter(
        (acc) => acc.most_common,
      );
      this.mostCommonAccessorials.value = this.sortMostCommonAccessorials();
      this.filterAccessorialsByType();
      this.isLoading = false;
    },
    sortMostCommonAccessorials() {
      const sortedAccessorials = this.mostCommonAccessorials.value.sort((a, b) => {
        if (a.accessorial_name.toLowerCase() < b.accessorial_name.toLowerCase()) {
          return -1;
        }
        if (a.accessorial_name.toLowerCase() > b.accessorial_name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      return sortedAccessorials;
    },
    checkHazmat(accessorial) {
      const hasHazmat = accessorial.accessorial_name === 'Hazmat';
      if (hasHazmat && this.mostCommonAccessorialsSelected.includes(accessorial)) {
        this.visible = true;
      }
    },
    closeModal() {
      this.visible = false;
    },
    downloadFormat() {
      const path = 'https://storage.googleapis.com/scales-v2/Templates/Template-ScalesRates%20(1).xlsx';
      const link = document.createElement('a');
      link.href = path;
      link.download = 'Template-Scales-V2';
      link.click();
    },
    clearFile() {
      this.file = null;
      this.$refs.sendQuoteForm.clearForm();
    },
    resetParentComponents() {
      this.subject = '';
      this.notify = [];
      this.customer = '';
      this.carriersSelected = [];
      this.modeSelected = {
        id: 'a0k1I0000005NnyQAE',
        name: 'LTL',
      };
      this.$store.commit('scale/setSubject', '');
      this.$store.commit('scale/setNotifyTo', []);
      this.$store.commit('scale/setCarriersExclude', []);
      const payload = {
        id: this.modeSelected.id,
        name: this.modeSelected.name,
      };
      this.$store.commit('scale/setMode', payload);
      this.$store.commit('scale/setCustomer', '');
      this.file = null;
      this.sonValid = true;
      this.verifyFieldsSend = true;
      this.$refs.sendQuoteForm.clearForm();
    },
  },
};
</script>

<style lang='scss' scoped>
.scales {
  display: flex;
  &__sidebar {
    width: 9%;
  }
  &__content {
    width: 91%;
    margin-top: 2rem;
    &__tittle {
      font-weight: bold;
    }
    &__separator {
      width: 98%;
      margin: 2rem auto;
    }
    &__row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin: 2rem 1.5rem;
      &__column {
        display: flex;
        width: 48%;
        justify-content: space-between;
        align-items: center;
      }
      &__content {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: column;
      }
      &__create-on {
        display: flex;
        width: 48%;
        justify-content: end;
        align-items: baseline;
      }
      &__autocomplete {
        display: flex;
        flex-direction: column;
        width: 80%;
        align-items: flex-start;
        &--not-found {
          font-size: 11px;
          color: $color-user-busy;
        }
      }
      &--button {
        @include primary-button;
      }
      &--button-next {
        @include primary-button;
        margin: 2rem 0 8rem;
      }
      &__input {
        width: 80%;
        &:hover {
          border-color: $color-primary-button;
        }
      }
      &__line {
        width: 100%;
      }
    }
    &__required {
      color: red;
    }
  }
}
.accessorials {
  margin-top: 18px;
}

.pickup-and-delivery-accessorials {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 18px;
  padding-left: 3%;
  gap: 15px;

  &__content {
    display: flex;
    justify-content: flex-start;
    margin: 0.5rem 0;
  }

  &__label {
    padding-left: 4px;
  }
}

.general-accessorials {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 75%;
  column-gap: 5px;
  row-gap: 0.5rem;
  padding-left: 3%;

  &__content {
    display: flex;
    justify-content: flex-start;
  }

  &__label {
    padding-left: 4px;
  }
}

.more-accessorials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5% 2%;
  border-top: 1px solid var(--color-border-container);

  &__content {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__checkbox {
    margin-right: 15px;
  }
}
@media (max-width: 900px) {
  .scales {
    &__sidebar {
      width: 0%;
    }
    &__content {
      width: 98%;
      margin-top: 7rem;
    }
  }
}
@media (max-width: 490px) {
  .general-accessorials {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
  }

  .more-accessorials {
    flex-direction: column;
    border: none;

    &__content {
      flex-direction: column;
      gap: 15px;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    &__button {
      width: 100%;
      margin-bottom: 25px;
    }
  }

  :deep(.accessorial-modal-button) {
    width: 100%;
  }
}
.alert{
  justify-items: center;
  &__content {
    width: 50%;
  }
}
.clear-button{
  border: none;
  background-color: transparent;
  z-index: 15;
  top: 0;
  bottom: 0;
  position: absolute;
  right: 80px;
}
:deep(.p-inputtext) {
  width: 100%;
  height: 31px;
}
:deep(.p-inputtext:enabled:hover) {
  border-color: $color-primary-button;
}
:deep(.p-multiselect) {
  height: 31px;
}
:deep(.p-multiselect .p-multiselect-label) {
  padding: 0.2rem 0.5rem;
}
:deep(.p-dropdown .p-dropdown-label) {
  padding: 0.2rem 0.5rem;
}
:deep(.p-multiselect .p-multiselect-label) {
  font-size: 15px;
  text-align: start;
}
:deep(.p-inputtext) {
  font-size: 15px;
  text-align: start;
}
:deep {
  .custom-file-input{
    width: 32rem;
  }
  .custom-file-label {
    &:after{
      background-color: #14a2b8 !important;
      color: #ffffff;
    }
  }
  @media (max-width: 900px) {
    .scales {
      &__sidebar {
        width: 0%;
      }
      &__content {
        width: 98%;
        margin-top: 7rem;
      }
    }
  }
  @media (max-width: 950px), (max-width: 1400px) {
    .scales {
      &__content {
        width: 90%;
        margin-top: 2rem;
      }
    }

    .select-form {
      width: 140px;
    }
  }
}
</style>
